<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

  export default {
    extends: BaseVue,
    data() {
      return {
        input:{},
        data: {},
        disabled:false,
        loading:false
      }
    },
    computed: {
      mrValidation(){
        return this.$root.config.mrValidation.contact
      }
    },
    async mounted() {
      await this.ready();
      this.getProjectContact()
    },
    methods:{
      getProjectContact() {
          Gen.apirest("/contact", {}, (err, resp)=>{
              if (err) console.log(err)
              this.data = resp.data
          })
      },
      onSubmit(e) {
        this.disabled=true
        this.loading=true
        Gen.apirest("/submit-contact",this.input,(err,resp)=>{
          if(err) return swal(err.resp.message,"","warning")
          this.input = {}
        this.disabled=false
        this.loading=false
          swal(resp.message,"","success")
        },"POST")
      },
    },
  };
</script>
<template>
<section id="content">
    <div class="content-wrap py-0 pb-3">
      <section id="page-title" class="page-title-mini">
        <div class="container clearfix">
          <h1>{{web.menu_contact}}</h1>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.menu_home}}</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">{{web.menu_contact}}</li>
          </ol>
        </div>
      </section>
      <section class="section nobg pb-0">
        <div class="container">
          <div class="contact-form">
            <div class="row">
              <div class="col-md-6">
                <VForm @resp="onSubmit" methode="post">
                  <div class="form-row">
                    <div class="col-lg-6 col-md-7">
                      <div class="form-group">
                        <h4 class="mb-0"><label for="ac_full_name">{{web.lbl_name}}  <span class="text-danger">*</span></label></h4>
                        <input type="text" name="ac_full_name" onkeydown="return fullNameKey(event)" v-model="input.ac_full_name" v-bind="validation('ac_full_name')" class="form-control">
                      </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-5">
                      <!-- <div class="form-group">
                        <h4 class="mb-0"><label for="ac_old">{{web.lbl_age}}  <span class="text-danger">*</span></label></h4>
                        <div class="input-group">
                          <input type="number" v-model="input.ac_old" v-bind="validation('ac_old')" max="60" min="5" name="ac_old" class="form-control" placeholder="ex: 26">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Tahun</span>
                          </div>
                        </div>
                      </div> -->
                      <div class="form-group">
                        <h4 class="mb-0"><label for="ac_old">{{web.lbl_age}}  <span class="text-danger">*</span></label></h4>
                        <input type="number" max="60" min="5" v-model="input.ac_old" v-bind="validation('ac_old')" name="ac_old" class="form-control">
                        <small class="text-ifo">Input 10, untuk usia 10 Tahun</small>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <h4 class="mb-0"><label for="ac_email">{{web.lbl_email}} <span class="text-danger">*</span></label></h4>
                        <input type="email" name="ac_email" onkeydown="return emailKey(event)" v-model="input.ac_email" v-bind="validation('ac_email')"  email="true" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <h4 class="mb-0"><label for="">{{web.lbl_topic}}  <span class="text-danger">*</span></label></h4>
                        <input type="text" name="ac_subject" v-model="input.ac_subject" v-bind="validation('ac_subject')" class="form-control">
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <h4 class="mb-0"><label for="ac_message">{{web.lbl_description}} <span class="text-danger">*</span></label></h4>
                    <textarea cols="30" rows="6" class="form-control" v-model="input.ac_message" v-bind="validation('ac_message')" ></textarea>
                  </div>
                  <div class="form-group text-right">
                    <button :disabled="disabled" type="submit" :class="loading ? 'cta--loading cta cta-primary cta--reveal cta--longer' : 'cta cta-primary cta--reveal cta--longer'"><div class="loading-text"><div class="spinner-grow spinner-grow-sm mr-1 align-middle" role="status" aria-hidden="true"></div><div class="d-inline-block align-middle">Mengirim ...</div></div><span>{{web.lbl_submit}}</span><i class="icon-line-arrow-right"></i></button>
                  </div>
                </VForm>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="wrap_circle">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 col-9">
                          <div class="contact_ic">
                            <div class="contact_ic_content">
                              <img :src="uploader(data.img)" :alt="'Kontak Hilo'"/>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-8 col_white" v-html="data.phone">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-html="data.disc"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>